<template>
	<div class="vcardpage">
		<span class="vcardpage__card">
			<div v-if="card" class="vcard">
				<div class="vcard__head">
					<span v-if="card.name" class="vcard__head__image">
						<template v-if="card.name.given">{{ card.name.given.split('')[0] }}</template><template v-if="card.name.family">{{ card.name.family.split('')[0] }}</template>
						<span class="vcard__head__share" @click="shareCard">
							<svg viewBox="0 0 50 50">
								<use xlink:href="#icon--share" width="100%" height="100%"></use>
							</svg>
						</span>
						<span class="vcard__head__edit" @click="editCard">
							<svg viewBox="0 0 50 50">
								<use xlink:href="#icon--edit" width="100%" height="100%"></use>
							</svg>
						</span>
					</span>
				</div>
				<div class="vcard__content">
					<div class="vcard__main">
						<span class="vcard__main__name">
							{{ card.name.given }} {{ card.name.family }}
						</span>
						<span v-if="card.org || card.title || card.role" class="vacrd__main__org org">
							<span v-if="card.org" class="org__name">
								{{ card.org }}
							</span>
							<small v-if="card.title" class="org__title">
								{{ card.title }}
							</small>
							<template v-if="card.title && card.role">-</template>
							<small v-if="card.role" class="org__role">
								{{ card.role }}
							</small>
						</span>
					</div>
					<div v-if="card.email && card.email.length" class="vcard__row">
						<span class="vcard__row__icon">
							<svg viewBox="0 0 50 50">
								<use xlink:href="#icon--mail" width="100%" height="100%"></use>
							</svg>
						</span>
						<span class="vcard__row__info">
							<span v-for="(e, eI) in card.email" :key="eI" class="vcard__row__info__item vcard__item">
								<span class="vcard__item__value">
									{{ e.value }}
								</span>
								<small class="vcard__item__type">
									{{ e.type }}
								</small>
							</span>
						</span>
					</div>
					<div v-if="card.phone && card.phone.length" class="vcard__row">
						<span class="vcard__row__icon">
							<svg viewBox="0 0 50 50">
								<use xlink:href="#icon--phone" width="100%" height="100%"></use>
							</svg>
						</span>
						<span class="vcard__row__info">
							<span v-for="(e, eI) in card.phone" :key="eI" class="vcard__row__info__item vcard__item">
								<span class="vcard__item__value">
									{{ e.value }}
								</span>
								<small class="vcard__item__type">
									{{ e.type }}
								</small>
							</span>
						</span>
					</div>
					<div v-if="card.url && card.url.length" class="vcard__row">
						<span class="vcard__row__icon">
							<svg viewBox="0 0 50 50">
								<use xlink:href="#icon--web" width="100%" height="100%"></use>
							</svg>
						</span>
						<span class="vcard__row__info">
							<span v-for="(e, eI) in card.url" v-if="!e.value.includes('facebook') && !e.value.includes('instagram') && !e.value.includes('linkedin') && !e.value.includes('twitter')" :key="eI" class="vcard__row__info__item vcard__item">
								<span class="vcard__item__value">
									{{ e.value }}
								</span>
								<small class="vcard__item__type">
									{{ e.type }}
								</small>
							</span>
						</span>
						<div class="vcard__socials">
							<a
								v-for="(e, eI) in card.url"
								v-if="e.value.includes('facebook') || e.value.includes('instagram') ||
								e.value.includes('linkedin') || e.value.includes('twitter')"
								:key="eI"
								class="vcard__socials__item"
								:href="e.value" target="_blank">
								<img :src="getSocialMediaIcon(e.value)" alt="" width="30" height="auto">
							</a>
						</div>
					</div>
					<span class="vcard__save btn outline" @click="saveCard">
						Salva nei contatti
					</span>
				</div>
			</div>
		</span>
		<span class="vcardpage__logo small">
			<img src="@/assets/images/logo-white.png" alt="" />
		</span>
	</div>
</template>
<script>
import { EventBus } from '@/events_bus.js';

export default {
	name: 'VCard',
	props: {
		info: {
			type: Object,
			required: true,
		},
		code: {
			type: String,
			required: true,
		}
	},
	data() {
		return {
			card: null,
		}
	},
	methods: {
		downloadToFile(content, filename, contentType) {
			const a = document.createElement('a');
			const file = new Blob([content], { type: contentType });

			a.href = URL.createObjectURL(file);
			a.download = filename;
			a.click();

			URL.revokeObjectURL(a.href);
		},
		saveCard() {
			this.$service.vcard.download(this.code).then(res => {
				this.downloadToFile(res, 'vcard.vcf', 'text/vcard');
			})
		},
		editCard() {
			console.log('EDIT REQUEST');
			EventBus.$emit('request-access');
		},
		shareCard() {
			EventBus.$emit('share-card');
		},
		getSocialMediaIcon(url) {
			if (url.includes('facebook')) {
				return require('@/assets/images/facebook.png');
			} else if (url.includes('instagram')) {
				return require('@/assets/images/instagram.png');
			} else if (url.includes('linkedin')) {
				return require('@/assets/images/linkedin.png');
			} else if (url.includes('twitter')) {
				return require('@/assets/images/twitter.png');
			}
		},
		init() {
			this.$service.vcard.get(this.$route.query.id).then(res => {
				if(res && res.data) {
					this.card = res.data;
					console.log('CARD', this.card);
				}
			})
		}
	},
	mounted() {
		this.init();
	}
}
</script>
<style lang="scss">
@import '~@/assets/scss/pages/vcard';
</style>
