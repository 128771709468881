<template>
	<div id="notfound" class="notfound">
		<span class="notfound__logo">
			<img src="@/assets/images/logo-white.png" alt="" />
		</span>
		<div class="notfound__text">
			<span>Siamo spiacenti!</span> questa pagina non esiste.
			<a href="https://www.autonoleggio-bonomo.it" target="_blank">
				Visita il nostro sito web
			</a>
		</div>
	</div>
</template>
<script>
export default {
	name: 'NotFound'
}
</script>
<style lang="scss">
.notfound {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	height: 90svh;
	&__logo {
		display: block;
		@include size(361px,110px);
		svg {
			display: block;
			@include size(100%,100%);
			fill: $color-white;
		}
		img {
			display: block;
			width: max-content;
			margin: 0 auto;
		}
	}
	&__text {
		display: block;
		width: 100%;
		font-size: 1.5rem;
		font-weight: 200;
		text-align: center;
		color: $color-white;
		margin-top: var(--supergutter);
		span {
			display: block;
			text-align: center;
			width: 100%;
			font-size: 2.3rem;
			font-variation-settings: 'wght' 400;
			font-style: italic;
			margin-bottom: var(--gutter);
		}
	}

	a {
		display: block;
		width: max-content;
		background: $color-gold;
		color: $color-black;
		padding: 12px 30px;
		margin: 60px auto 0;
	}
}
</style>
